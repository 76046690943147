import React from 'react';
import Helmet from 'react-helmet';
import { Section, Container } from '../components/common';
import { Navigation } from '../components/layout/index.js';
import DataLayer from '../components/layout/DataLayer';

const NotFoundPage = () => (
  <>
    <Helmet>
      <title>404</title>
    </Helmet>
    <Navigation darkmenu />
    <Section lightBackground>
      <Container maxWidth="900px">
        <h1>Stink! We broke the internet</h1>
        <p>
          Sorry something seems to have gone wrong an you found a page that does
          not exist. Please use the menu above.{' '}
        </p>
      </Container>
    </Section>
    <DataLayer event="404" />
  </>
);

export default NotFoundPage;
